<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlJzqglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="4" style="height: 100%;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </el-col>
        <el-col :span="20" style="height: 100%;">
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <div v-if="isFirstIn" style="padding: 20% 0 0 40%;color: #aaa;">请选择左侧区域查询数据</div>
            <basic-table v-else :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal"></basic-table >
          </div>
        </el-col>
      </el-row>
    </div>
    

    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 编辑弹窗 -->
    <general-form :FormDialog="FormDialogEdit" :FormList="FormList" :title="editTitle" :FormData="modifyForm"  @cancelDialog="cancelDialogEdit" @confirmDialog="confirmDialogEdit"></general-form>

    

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        isFirstIn:true,//首次进入页面
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          nowState:'0',
          key: undefined
        },
        orgs: [], // 用户可访问到的组织列表
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{ },//添加表单
        modifyForm:{},//编辑表单
        FormList:{//表格列
          column:[
            {columnKey:'concentratorName',columnDescription:'集中器名称',columnType:'text',prop:'concentratorName',width:'120',placeholder:"集中器名称",},
            {columnKey:'concentratorAddress',columnDescription:'集中器地址',columnType:'text',prop:'concentratorAddress',width:'120',placeholder:"集中器地址",},
            {columnKey:'belongsArea',columnDescription:'所属区域',columnType:'text',prop:'belongsArea',width:'120',placeholder:"所属区域",},
            {columnKey:'installAddress',columnDescription:'安装地址',columnType:'text',prop:'installAddress',width:'120',placeholder:"安装地址",},
            {columnKey:'communicationProtocol',columnDescription:'通讯规约',columnType:'text',prop:'communicationProtocol',width:'120',placeholder:"通讯规约",},
            {columnKey:'programmePlatform',columnDescription:'方案所属平台',columnType:'text',prop:'programmePlatform',width:'120',placeholder:"方案所属平台",},
            // {columnKey:'project',columnDescription:'项目',columnType:'text',prop:'project',width:'120',placeholder:"项目",},
          ],
          //校验
          rule:{
            concentratorName: [{ required: true, message: "集中器名称不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        FormDialogEdit:false,//编辑弹窗
        editTitle:'修改',//编辑弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
      }
    },
    watch: {

    },
    computed: {
      orgsTree() {
        return this.$store.state.AllArea;
      },
    },
    filters: {
      
    },
    created() {
      // this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
        // this.CheckedKey = data
        // var s = this.CheckedKey.length
        // this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.tableChooseRow.id){
              this.handleDelete(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnEdit'://编辑
            if(this.tableChooseRow.id){
              this.modify(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        basicDataApi.jzqglGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
            this.isFirstIn = false
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
        })
        // this.tableData = this.$store.state.tableDataSearch
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.$confirm(`确定要删除集中器名称为${row.concentratorName}的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = [row.id]
          basicDataApi.jzqglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.reset()//重置
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
         basicDataApi.jzqglAdd(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialog = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialog = false
        })
        this.reset()//重置
      },
      //重置
      reset(){
        this.addform = this.modifyForm ={
          concentratorName:'',
          concentratorAddress:'',
          belongsArea:'',
          installAddress:'',
          communicationProtocol:'',
          programmePlatform:'',
          project:'',
        }
        this.tableChooseRow = {}
      },
      // 编辑表
      modify(row){
        this.modifyForm = JSON.parse(JSON.stringify(row))
        this.FormDialogEdit = true
      },
      // 编辑弹窗取消
      cancelDialogEdit(val){
        this.FormDialogEdit = val
      },
      // 编辑弹窗提交
      confirmDialogEdit(){
        basicDataApi.jzqglModify(this.modifyForm).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialogEdit = false
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogEdit = false
        })
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
